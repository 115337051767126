import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import cn from 'classnames';
import { noop } from 'lodash';

import { Icon, Image, IconName, Spinner } from 'components';
import { Button, Checkbox, Input } from 'components/form';
import { ButtonV3 } from 'components/ComponentV2';
import { useResponsive } from 'hooks/useResponsive';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { Option } from 'lib/models/option';
import { formatToK } from 'utils/format';

import styles from './MultiSelect.module.scss';

export type Position = 'top' | 'bottom';

export interface MultiSelectCommonProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** title */
  title: string;
  /** values */
  values: Array<string>;
  /** options */
  options?: Array<Option>;
  /** onChange handler */
  onChange: (values: Array<string>) => void;
  /** is it searchable? */
  isSearchable?: boolean;
  /** alignment */
  alignment?: 'left' | 'right' | 'center' | 'static';
  /** placeholder text for search */
  placeholder?: string;
  /** className for dropdown body */
  dropdownClassName?: string;
  /** show selected values count ?*/
  showCount?: boolean;
  disableOptions?: boolean;
  customButton?: React.ElementType;
  position?: Position;
  fullWidth?: boolean;
  triggerClassName?: string;
  hasBorder?: boolean;
  buttonTitleClassName?: string;
  triggerOpenClassName?: string;
  isEllipsisLabel?: boolean;
  showCountWithLabel?: boolean;
  iconClassName?: IconName;
  checkBoxClassName?: string;
  scrollToDropdown?: () => void;
  showClearButton?: boolean;
  optionLabelFirst?: boolean;
  showSingleValueLabel?: boolean;
  onFetchMoreData?: () => void;
  onSearch?: (searchText: string) => void;
  showRefLoader?: boolean;
}

type MultiRefs = {
  listRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

type ApplyOnChangeProps = {
  changeOnShow?: false;
};

type ApplyOnShowProps = {
  changeOnShow: true;
  partialCount: number;
  partialLoading: boolean;
  partialValues?: Array<string> | null;
  onPartialChange: (value?: string[] | null) => void;
};

type NonPartialProps = MultiSelectCommonProps & ApplyOnChangeProps;
type PartialProps = MultiSelectCommonProps & ApplyOnShowProps;
export type MultiSelectProps = NonPartialProps | PartialProps;

const isApplyOnShow = (props: MultiSelectProps): props is PartialProps =>
  (props as PartialProps).changeOnShow;

const MultiSelectComponent: ForwardRefRenderFunction<
  MultiRefs,
  MultiSelectProps
> = (props, ref) => {
  const {
    className = '', // custom class name
    style, // custom style
    title,
    values,
    options,
    onChange,
    alignment = 'left',
    placeholder,
    isSearchable,
    showCount = false,
    dropdownClassName,
    disableOptions = false,
    customButton: Component,
    position = 'bottom',
    fullWidth = false,
    triggerClassName,
    hasBorder = false,
    buttonTitleClassName,
    triggerOpenClassName = '',
    isEllipsisLabel = true,
    showCountWithLabel = true,
    iconClassName,
    checkBoxClassName,
    scrollToDropdown,
    showClearButton = true,
    optionLabelFirst = false,
    showSingleValueLabel = false,
    onFetchMoreData,
    onSearch,
    showRefLoader,
  } = props;

  const contentClassNames = cn(
    {
      [styles.MultiSelect]: true,
      [styles.MultiSelectFullWidth]: fullWidth,
    },
    className
  );

  const screens = useResponsive();

  const multiSelectRef = useRef(null);
  const listRef = useRef<HTMLInputElement>(null);
  const bottomListRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const trimmedSearch = search.trim();

  useImperativeHandle(ref, () => ({
    listRef: listRef,
    buttonRef: buttonRef,
  }));

  useVisibleOnIntersect({
    elRef: bottomListRef,
    threshold: 0,
    onIntersect: () => onFetchMoreData && onFetchMoreData(),
    rootMargin: '50px',
  });

  useEffect(() => {
    onSearch && onSearch(search);
  }, [search]);

  useEffect(() => {
    if (open === false) {
      setSearch('');
      buttonRef.current?.setAttribute('aria-expanded', 'false');
    } else {
      buttonRef.current?.setAttribute('aria-expanded', 'true');
    }
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      screens.sm && updateDropdownMaxHeight();
    };
    window?.addEventListener('scroll-top', handleScroll);

    return () => {
      window?.removeEventListener('scroll-top', handleScroll);
    };
  }, [screens.sm]);

  useEffect(() => {
    if (screens.sm && open) {
      scrollToDropdown && scrollToDropdown();
    }
  }, [screens.sm, open, options?.length]);

  const dropdownClassNames = cn(
    {
      [styles.MultiSelectDropdown]: true,
      [styles.MultiSelectDropdownAlignLeft]: alignment === 'left',
      [styles.MultiSelectDropdownAlignRight]: alignment === 'right',
      [styles.MultiSelectDropdownAlignCenter]: alignment === 'center',
      [styles.MultiSelectDropdownTop]: position === 'top',
      [styles.MultiSelectDropdownBottom]: position === 'bottom',
      [styles.MultiSelectDropdownFullWidth]: fullWidth,
    },
    dropdownClassName
  );

  const onClose = useCallback(() => {
    setOpen(false);

    if (open && isApplyOnShow(props)) {
      props.onPartialChange();
    }
  }, [open, props]);

  const selectedValues = isApplyOnShow(props)
    ? typeof props.partialValues !== 'undefined'
      ? props.partialValues || []
      : values
    : values;
  const onChangeHandler = isApplyOnShow(props)
    ? props.onPartialChange
    : onChange;

  const onValueChange = (value: string) => {
    const newValues = selectedValues.includes(value)
      ? selectedValues.filter((val) => val !== value)
      : [...selectedValues, value];
    onChangeHandler(newValues);
  };

  const onClear = () => {
    onChange([]);
  };

  const onReset = () => {
    if (isApplyOnShow(props)) {
      props.onPartialChange(null);
    }
  };

  const onShow = useCallback(() => {
    onChange(selectedValues);
    onClose();
  }, [selectedValues, onClose, onChange]);

  useOnClickOutside({
    ref: multiSelectRef,
    onOutsideClick: open ? (isApplyOnShow(props) ? onShow : onClose) : noop,
  });

  const filteredOptions = useMemo(() => {
    if (!options) return null;

    return isSearchable && !onSearch
      ? options.filter(
          (option) =>
            option.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) > -1
        )
      : options;
  }, [trimmedSearch, options, isSearchable, onSearch]);

  const handleToggle = () => {
    if (open) {
      onShow();
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const updateDropdownMaxHeight = () => {
    if (listRef.current && screens.sm) {
      const dropdownRect = listRef.current.getBoundingClientRect();
      const maxHeight = window.innerHeight - dropdownRect.top - 12;
      listRef.current.style.maxHeight = `${maxHeight}px`;
      listRef.current.style.overflow = 'auto';
    }
  };

  const singleValueLabel = useMemo(() => {
    if (!options) return '';

    if (values.length === 1) {
      const option = options.find((option) => option.value === values[0]);
      return option?.label;
    }
    return '';
  }, [values, options]);

  return (
    <section
      className={contentClassNames}
      style={style}
      data-testid="MultiSelect"
      ref={multiSelectRef}
    >
      {Component ? (
        <Component onClick={() => setOpen(!open)} />
      ) : (
        <button
          className={cn(
            styles.MultiSelectTrigger,
            {
              [styles.MultiSelectTriggerOpen]: open,
              [styles.MultiSelectTriggerFullWidth]: fullWidth,
              [styles.MultiSelectTriggerHasBorder]: hasBorder,
              [triggerOpenClassName]: open && !values?.length,
              [styles['apply-on-show']]: isApplyOnShow(props),
              [styles['selected']]: values?.length,
            },
            triggerClassName
          )}
          onClick={handleToggle}
          type="button"
          ref={buttonRef}
        >
          <p
            className={cn(buttonTitleClassName, {
              [styles.valuesEllipsis]: isEllipsisLabel,
            })}
          >
            {showSingleValueLabel && singleValueLabel
              ? singleValueLabel
              : showCountWithLabel && values.length
              ? `${title} (${values.length})`
              : title}
          </p>
          <Icon
            iconName="icon_arrow-down"
            size="xsmall"
            className={cn(styles.MultiSelectIcon, iconClassName, {
              [styles.MultiSelectIconReverse]: open,
            })}
          />
          {showCount ? (
            <div className={styles.MultiSelectSelectedCount}>
              {values.length ? <span>{values.length}</span> : null}
            </div>
          ) : null}
        </button>
      )}
      {open ? (
        <div className={dropdownClassNames} ref={listRef}>
          {isSearchable ? (
            <div className={styles.MultiSelectDropdownSearch}>
              <Input
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className={styles.MultiSelectDropdownSearchInput}
                startIconClassName={styles.MultiSelectDropdownSearchIcon}
                placeholder={placeholder}
                startIcon="search"
                startIconSize="small"
                endIconSize="xsmall"
                endIcon={search ? 'cancel' : undefined}
                onEndIconClick={() => setSearch('')}
                autoFocus={true}
              />
            </div>
          ) : null}
          <ul className={styles.MultiSelectDropdownOptions}>
            {filteredOptions?.map(({ id, value, label, avatar }) => {
              const checked = selectedValues.includes(value);
              const isDisabled = disableOptions
                ? !values.includes(value)
                : false;
              return (
                <li
                  key={id}
                  className={cn(styles.MultiSelectDropdownOption, {
                    [styles.MultiSelectDropdownOptionSelected]: checked,
                    [styles.MultiSelectDropdownOptionLabelFirst]:
                      optionLabelFirst,
                  })}
                  onClick={() => !isDisabled && onValueChange(value)}
                >
                  <Checkbox
                    checked={checked}
                    labelFirst={optionLabelFirst}
                    readOnly
                    className={cn(
                      styles.MultiSelectDropdownOptionCheckbox,
                      checkBoxClassName,
                      {
                        [styles.MultiSelectDropdownOptionCheckboxDisabled]:
                          isDisabled,
                      }
                    )}
                    disabled={isDisabled}
                  >
                    {avatar ? <Image src={avatar} alt={label} /> : null}
                    <span>{label}</span>
                  </Checkbox>
                </li>
              );
            })}
            {onFetchMoreData && (
              <div className={styles.spinner} ref={bottomListRef}>
                {showRefLoader && typeof options !== 'undefined' && (
                  <Spinner size="xxsmall" />
                )}
              </div>
            )}
          </ul>
          {filteredOptions?.length === 0 ? (
            <p className={styles.MultiSelectDropdownNoResults}>
              No results found.
            </p>
          ) : null}
          {typeof options === 'undefined' && (
            <div className={styles.spinner}>
              <Spinner size="small" />
            </div>
          )}
          {showClearButton ? (
            <div className={styles.MultiSelectDropdownFooter}>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={onClear}
                type="button"
              >
                Clear
              </Button>
            </div>
          ) : null}
          {isApplyOnShow(props) && (
            <footer className={styles.actions}>
              {selectedValues.length > 0 ? (
                <ButtonV3 color="secondary" size="small" onClick={onReset}>
                  Reset
                </ButtonV3>
              ) : (
                <ButtonV3 color="secondary" size="small" onClick={onClose}>
                  Cancel
                </ButtonV3>
              )}
              <ButtonV3
                size="small"
                className={styles['show-button']}
                onClick={onShow}
                isLoading={props.partialLoading}
              >{`Show ${formatToK(props.partialCount)}`}</ButtonV3>
            </footer>
          )}
        </div>
      ) : null}
    </section>
  );
};

export const MultiSelect = memo(forwardRef(MultiSelectComponent));

MultiSelect.displayName = 'MultiSelect';
