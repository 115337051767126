import { useEffect } from 'react';

interface ElementRef {
  current: Element | null;
}

/**
 * Hook that uses IntersectionObserver to execute a functionality (such as toggling visibility on/off) of DOM elements when they appear on screen
 * @param {ElementRef} elRef: React ref that holds references to the DOM element
 * @param {number} threshold: Intersection observer threshold
 * @param {Function} onIntersect: function to execute when intersection condition is true
 * @example
 * useVisibleOnIntersect({elRef, threshold onIntersect})
 */
export const useVisibleOnIntersect = ({
  elRef,
  rootElement,
  threshold,
  onIntersect,
  onHidden,
  rootMargin,
  isEqualToThreshold,
  reactOnFullHidden,
}: {
  rootElement?: Element | null;
  elRef: ElementRef;
  threshold: number;
  onIntersect?: () => void;
  onHidden?: () => void;
  rootMargin?: string;
  isEqualToThreshold?: boolean;
  reactOnFullHidden?: boolean;
}): void => {
  useEffect(() => {
    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      if (!onIntersect) return;
      const entry = entries.pop();
      if (!entry) return;

      if (reactOnFullHidden) {
        // @ts-ignore
        const { intersectionRatio, isIntersecting, isVisible } = entry;
        if (intersectionRatio === 0 && !isIntersecting && !isVisible) {
          onHidden && onHidden();
        } else {
          onIntersect();
        }
        return;
      }
      if (
        entry.intersectionRatio > threshold ||
        (isEqualToThreshold ? entry.intersectionRatio === threshold : false)
      ) {
        onIntersect();
      } else {
        onHidden && onHidden();
      }
    };
    const el = elRef.current;
    let observer: IntersectionObserver | null = null;
    if (el) {
      const options = {
        root: rootElement || null,
        rootMargin: rootMargin || '0px',
        threshold,
      };
      observer = new IntersectionObserver(intersectionCallback, options);
      observer.observe(el);
    }

    return () => {
      if (el && observer) {
        observer.unobserve(el);
      }
    };
  }, [
    elRef,
    rootElement,
    onIntersect,
    onHidden,
    rootMargin,
    threshold,
    reactOnFullHidden,
  ]);
};
