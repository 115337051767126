import React, { memo } from 'react';
import cn from 'classnames';

import { useRouter } from 'next/router';
import IconButton from 'components/IconButton';
import { useResponsive } from 'hooks/useResponsive';

import styles from '../Onboarding.module.scss';

export type MobileHeaderMpProps = {
  title: string;
  onChevronClick: () => void;
};

export const MobileHeaderMp: React.FC<MobileHeaderMpProps> = memo(
  ({ title, onChevronClick }) => {
    const screens = useResponsive();
    const router = useRouter();
    const { matching_preference } = router.query;

    return (
      <div
        className={cn(styles.header, {
          [styles.navHeader]: screens.sm,
          [styles.hideHeader]: !screens.sm || !matching_preference,
        })}
      >
        <IconButton
          iconName="chevron-left"
          size="xlarge"
          className={styles.icon}
          onClick={onChevronClick}
        />
        {title}
      </div>
    );
  }
);

MobileHeaderMp.displayName = 'MobileHeaderMp';
