import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

import { ButtonV3 } from 'components/ComponentV2';
import { useResponsive } from 'hooks/useResponsive';
import { mpStepSelectModalInstance } from '../MpStepSelectModal/MpStepSelectModal.component';

import styles from './Footer.module.scss';

export interface FooterProps {
  className?: string;
  primaryCtaText?: string;
  onSubmit: (isBackClicked?: boolean) => void;
  isBackLoading: boolean;
  isLoading: boolean;
  isReady: boolean;
  isWindowScrolled: boolean;
  isFullyScrolled: boolean;
  isSecondaryCtaDisabled?: boolean;
  isSkippable?: boolean;
  isMatchingPreference?: boolean;
}

export const Footer: React.FC<FooterProps> = memo(
  ({
    className,
    primaryCtaText,
    onSubmit,
    isBackLoading,
    isLoading,
    isReady,
    isWindowScrolled,
    isFullyScrolled,
    isSecondaryCtaDisabled = false,
    isSkippable,
    isMatchingPreference,
  }: FooterProps) => {
    const [tCommon] = useTranslation('common');
    const screens = useResponsive();

    const primaryButtonText = useMemo(() => {
      if (primaryCtaText) {
        return primaryCtaText;
      }
      if (isMatchingPreference && screens.sm) {
        return tCommon('save');
      }
      return tCommon('next');
    }, [primaryCtaText, isMatchingPreference, screens.sm]);

    const onBackClick = () => {
      if (isMatchingPreference && !isReady && !isSkippable) {
        mpStepSelectModalInstance();
      } else onSubmit(true);
    };

    return (
      <div
        className={cn(
          className,
          styles.footer,
          {
            [styles.footerScrolled]: isWindowScrolled || isMatchingPreference,
          },
          {
            [styles.fullyScrolled]: isFullyScrolled,
          }
        )}
      >
        <div className={styles.wrapper}>
          {screens.sm && isMatchingPreference ? (
            <ButtonV3
              onClick={() => onSubmit()}
              disabled={!isReady && !isSkippable}
              isLoading={isLoading}
              className={cn(styles.nextButton, {
                [styles.nextButtonDisabled]: !isReady && !isSkippable,
              })}
              borderless
              color={isSkippable && !isReady ? 'secondary' : 'primary'}
            >
              {primaryButtonText}
            </ButtonV3>
          ) : (
            <>
              <ButtonV3
                disabled={isSecondaryCtaDisabled}
                color="secondary"
                onClick={onBackClick}
                isLoading={isBackLoading}
                className={cn(styles.nextButton, {
                  [styles['hide-mobile']]: isMatchingPreference,
                })}
                borderless
              >
                {tCommon('back')}
              </ButtonV3>
              {isSkippable && !isReady ? (
                <ButtonV3
                  color={'secondary'}
                  borderless
                  onClick={() => onSubmit()}
                  isLoading={isLoading}
                  className={styles.nextButton}
                >
                  {tCommon('skip')}
                </ButtonV3>
              ) : (
                <ButtonV3
                  onClick={() => onSubmit()}
                  disabled={!isReady}
                  isLoading={isLoading}
                  className={cn(styles.nextButton, {
                    [styles.nextButtonDisabled]: !isReady,
                  })}
                  borderless
                >
                  {primaryButtonText}
                </ButtonV3>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

Footer.displayName = 'Footer';
