import React from 'react';
import { InstanceProps, create } from 'react-modal-promise';
import { useTranslation } from 'next-i18next';

import { IconButton } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import Modal from 'components/Modal';

import styles from './MpStepSelectModal.module.scss';

const ModalInstance: React.FC<InstanceProps<void>> = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  const [tCommon] = useTranslation('common');
  const [t] = useTranslation('candidate-onboarding');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
      className={styles.modal}
    >
      <div>
        <div className={styles.header}>
          <p className={styles.title}>{t('select-an-option')}</p>
          <IconButton
            size="small"
            iconName="close"
            className={styles['close-icon']}
            onClick={() => onReject()}
          />
        </div>
        <div className={styles.desc}>{t('mandotary-step-desc')}</div>
        <div className={styles.footer}>
          <ButtonV3 className={styles.button} onClick={() => onResolve()}>
            {tCommon('ok')}
          </ButtonV3>
        </div>
      </div>
    </Modal>
  );
};

export const mpStepSelectModalInstance = create(ModalInstance);
