import { RefObject } from 'react';

const useScrollOffset = () => {
  const getOffsetTopRelativeToContainer = (
    element: HTMLElement,
    container: HTMLElement
  ): number => {
    let offsetTop = 0;
    while (element && element !== container) {
      offsetTop += element.offsetTop;
      element = element.offsetParent as HTMLElement;
    }
    return offsetTop - (container ? container.offsetTop : 0);
  };

  const getScrollOffset = (
    elementRef: RefObject<HTMLElement>,
    containerRef: RefObject<HTMLElement>,
    headerRef: RefObject<HTMLElement>,
    adjustment: number
  ): number => {
    if (!elementRef.current || !containerRef.current) return 0;
    const offsetHeader = headerRef?.current?.offsetHeight;
    const headerHeight = offsetHeader ? offsetHeader : 0;
    const offsetTop = getOffsetTopRelativeToContainer(
      elementRef.current,
      containerRef.current
    );

    return offsetTop - headerHeight - adjustment;
  };

  const isWindowScrolled = window.innerHeight < document.body.clientHeight;

  return { getScrollOffset, isWindowScrolled };
};

export default useScrollOffset;
