import React from 'react';
import { InstanceProps, create } from 'react-modal-promise';
import { useTranslation } from 'next-i18next';

import { IconButton } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import Modal from 'components/Modal';
import { FLAG_STATUS_CODES } from 'hooks/useCandidate';

import styles from './SaveConfirmationModal.module.scss';

const ModalInstance: React.FC<InstanceProps<FLAG_STATUS_CODES>> = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  const [tCommon] = useTranslation('common');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
      className={styles.modal}
    >
      <div>
        <div className={styles.header}>
          <p className={styles.title}>{tCommon('unsaved-changes')}</p>
          <IconButton
            size="small"
            iconName="close"
            className={styles['close-icon']}
            onClick={() => onReject()}
          />
        </div>
        <div className={styles.desc}>
          {tCommon('discard-changes-confirmation')}
        </div>
        <div className={styles.footer}>
          <div className={styles['buttom-wrapper']}>
            <ButtonV3
              isFullWidth
              color="secondary"
              className={styles.button}
              onClick={() => onReject(FLAG_STATUS_CODES.CANCEL)}
            >
              {tCommon('cancel')}
            </ButtonV3>
            <ButtonV3
              isFullWidth
              color="danger"
              className={styles.button}
              onClick={() => onResolve(FLAG_STATUS_CODES.SUCCESS)}
            >
              {tCommon('yes-discard')}
            </ButtonV3>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const saveConfirmationModalInstance = create(ModalInstance);
