import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { debounce } from 'lodash';

import { Spinner } from 'components';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { Input } from 'components/form';
import { Row } from 'components/layout';
import { MatchingPreferenceFooter } from 'components/InvitePopup/MatchinngPreferenceFooter/MatchingPreferenceFooter';
import { useCandidate } from 'hooks/useCandidate';
import { useNotification } from 'hooks/useNotification';
import { useResponsive } from 'hooks/useResponsive';
import { SelectedFilterOptions } from 'hooks/useFilter';
import useScrollOffset from 'hooks/useScrollOffset';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { useCandidateOnboarding } from 'hooks/useCandidateOnboarding';
import { Option } from 'lib/models/option';
import { MATCHING_PREFERENCE_STEPS } from 'lib/models/candidate';
import { sortedCountriesList } from 'utils/format';
import { Footer } from '../Footer/Footer.component';
import { SidebarMP } from '../SidebarMP/SidebarMP.component';
import MobileHeaderMp from '../MobileHeaderMp';

import styles from '../Onboarding.module.scss';

export interface RemotePreferenceProps {
  /** Custom class name */
  className?: string;
  candidateChoice: Array<string>;
  countries: Array<Option> | undefined;
  onNextClick: (
    preferenceSelected: Array<string>,
    isBackClicked?: boolean
  ) => void;
  isLoading: boolean;
  isBackLoading: boolean;

  // This prop is for Filter component modal
  openOnModal?: boolean;
  isMatchingPreferences: boolean;
  setSelectedOptions?: Dispatch<SetStateAction<SelectedFilterOptions>>;
  isSocietyMatchingPreference?: boolean;
  onStepSelect?: (step: MATCHING_PREFERENCE_STEPS) => void;
}

export const RemotePreference: React.FC<RemotePreferenceProps> = memo(
  ({
    className = '', // custom class name
    candidateChoice,
    onNextClick,
    isLoading,
    isBackLoading,
    openOnModal = false,
    isMatchingPreferences,
    countries,
    setSelectedOptions,
    onStepSelect,
    isSocietyMatchingPreference,
  }: RemotePreferenceProps) => {
    const [t] = useTranslation('candidate-onboarding');
    const [tCommon] = useTranslation('common');
    const screens = useResponsive();
    const notificationInstance = useNotification();
    const { updateCandidateInfo } = useCandidate();

    const { isWindowScrolled } = useScrollOffset();
    const bottomRef = useRef<HTMLDivElement>(null);

    const contentClassNames = cn(styles.container, className);
    const [fullyScrolled, setFullyScrolled] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [selectedCountry, setSelectedCountry] = useState<Array<string>>([]);

    const [search, setSearch] = useState('');

    const countriesList = useMemo(() => {
      return sortedCountriesList(countries);
    }, [countries]);

    useEffect(() => {
      setSelectedCountry(candidateChoice);
    }, [candidateChoice]);

    const isReady = !!selectedCountry.length;
    const { isValueEdited, setValueEdited, onBackClickMPMobile } =
      useCandidateOnboarding({ isReady });

    useEffect(() => {
      if (!openOnModal) return;
      if (candidateChoice?.length > 0 && selectedCountry?.length === 0) {
        setValueEdited(true);
      } else setValueEdited(false);
    }, [candidateChoice, selectedCountry, openOnModal]);

    const onChevronClick = () => {
      if (isValueEdited) {
        onBackClickMPMobile();
      } else {
        onSubmit(false);
      }
    };

    useVisibleOnIntersect({
      elRef: bottomRef,
      threshold: 0,
      onIntersect: () => setFullyScrolled(true),
      onHidden: () => setFullyScrolled(false),
      rootMargin: '0px',
    });

    const apiCallToUpdate = async (modifiedSelection: Array<string>) => {
      try {
        await updateCandidateInfo({
          partialCandidateAttributes: {
            country_ids: modifiedSelection,
          },
        });
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };
    const debouncedAPICall = React.useRef(
      debounce(async (modifiedSelection) => {
        await apiCallToUpdate(modifiedSelection);
      }, 3000)
    ).current;
    useEffect(() => {
      if (openOnModal && setSelectedOptions) {
        if (selectedCountry?.length > 0) {
          setSelectedOptions((options) => ({
            ...options,
            country_ids: selectedCountry,
          }));
        } else if (
          candidateChoice?.length > 0 &&
          selectedCountry?.length === 0 &&
          isValueEdited
        ) {
          setSelectedOptions((options) => ({
            ...options,
            country_ids: [],
          }));
        }
      }
    }, [selectedCountry, openOnModal, isValueEdited]);

    const onSubmit = (isBackClicked?: boolean) => {
      setValueEdited(false);
      onNextClick(selectedCountry, isBackClicked);
    };

    const onCountrySelect = (countryId: string) => {
      setValueEdited(true);
      const index = selectedCountry?.findIndex((id) => id === countryId);
      let modifiedSelection = [];
      if (index === -1) modifiedSelection = [...selectedCountry, countryId];
      else
        modifiedSelection =
          selectedCountry &&
          selectedCountry.filter((item) => item !== countryId);

      /** For MP
       * Desktop: Autosave on every change
       *          Do not make changes when nothing is selected on mandatory step
       * Mobile: Save on CTA click
       */
      setSelectedCountry(modifiedSelection);
      if (modifiedSelection.length === 0) return;

      if (
        isMatchingPreferences &&
        !isSocietyMatchingPreference &&
        !screens.sm
      ) {
        debouncedAPICall(modifiedSelection);
      }
    };

    const trimmedSearch = search.trim();
    const filteredOptions = useMemo(() => {
      return search
        ? countriesList?.filter(
            (resp) =>
              resp.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) > -1
          )
        : countriesList;
    }, [countriesList, search]);

    const countriesListDisplay = useMemo(() => {
      return (
        <div
          className={cn(styles.checkboxes, {
            [styles.modalCheckboxes]: openOnModal,
          })}
        >
          {filteredOptions?.map((country) => (
            <Row
              key={country.id}
              align="center"
              justify="space-between"
              wrap={false}
              className={cn(styles.checkboxesLocationRow, {
                [styles.modalCheckboxesRow]: openOnModal,
              })}
            >
              <SelectionTickMark
                key={country.id}
                checked={selectedCountry?.some((id) => country.id === id)}
                onChange={() => onCountrySelect(country.id)}
                className={cn(
                  { [styles.selectionbox]: !openOnModal },
                  { [styles.modalSelectionbox]: openOnModal }
                )}
                isTickSquare={true}
              >
                {country.label}
              </SelectionTickMark>
              {openOnModal ? <hr className={styles.separator}></hr> : null}
            </Row>
          ))}
        </div>
      );
    }, [filteredOptions, selectedCountry]);

    if (!countries) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={styles['content-contianer']}>
        {!isSocietyMatchingPreference && (
          <MobileHeaderMp
            title={t('matching-preferences.remote-locations')}
            onChevronClick={onChevronClick}
          />
        )}

        <div
          className={cn(contentClassNames, {
            [styles['container-society']]: isSocietyMatchingPreference,
          })}
          data-testid="RemotePreference"
          ref={containerRef}
        >
          {!isSocietyMatchingPreference &&
            isMatchingPreferences &&
            onStepSelect && (
              <SidebarMP
                isReady={isReady}
                className={styles.sidebar}
                onStepSelect={onStepSelect}
              />
            )}

          <div
            className={cn({
              [styles.content]: !openOnModal,
              [styles['content-mp']]:
                !openOnModal &&
                isMatchingPreferences &&
                !isSocietyMatchingPreference,
              [styles['content-width-society']]: isSocietyMatchingPreference,
              [styles.fullWidth]: openOnModal,
            })}
          >
            {isSocietyMatchingPreference ? (
              <p className={styles.fractionalStepper}>2/3</p>
            ) : null}
            {openOnModal ? null : (
              <p className={cn({ [styles.setup]: !openOnModal })}>
                {isSocietyMatchingPreference
                  ? tCommon(
                      'society-matching-preference.remote-preference-title'
                    )
                  : t('remote-preference.title')}
              </p>
            )}
            <Input
              className={cn(styles.search)}
              placeholder="Search"
              startIcon="search"
              startIconSize="small"
              endIconSize="xsmall"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              endIcon={search ? 'cancel' : undefined}
              onEndIconClick={() => setSearch('')}
            />
            {countriesListDisplay}
            <div className={styles['bottom-ref']} ref={bottomRef}></div>
          </div>
          <div ref={bottomRef}></div>
          {!openOnModal ? (
            !isSocietyMatchingPreference ? (
              <Footer
                isBackLoading={isBackLoading}
                isReady={isReady}
                isLoading={isLoading}
                onSubmit={onSubmit}
                isFullyScrolled={fullyScrolled}
                isWindowScrolled={isWindowScrolled}
                isMatchingPreference={isMatchingPreferences}
                primaryCtaText={tCommon('next')}
              />
            ) : (
              <MatchingPreferenceFooter
                isBackLoading={isBackLoading}
                isReady={isReady}
                onSubmit={onSubmit}
                primaryCtaText={tCommon('next')}
                secondaryCtaText={tCommon('back')}
                isLoading={isLoading}
              />
            )
          ) : null}
        </div>
      </div>
    );
  }
);

RemotePreference.displayName = 'RemotePreference';
