import React, { memo } from 'react';

import { Row } from 'components/layout';
import { ButtonV3 } from 'components/ComponentV2';
import styles from './MatchingPreferenceFooter.module.scss';

export interface MatchingPreferenceFooterProps {
  primaryCtaText: string;
  secondaryCtaText: string;
  onSubmit: (isBackClicked?: boolean) => void;
  isBackLoading: boolean;
  isLoading: boolean;
  isReady: boolean;
}

export const MatchingPreferenceFooter: React.FC<MatchingPreferenceFooterProps> = memo(
  ({ primaryCtaText, secondaryCtaText, onSubmit, isBackLoading, isLoading, isReady }: MatchingPreferenceFooterProps) => {
    return (
      <Row align="center" justify="space-between" isFullWidthRow className={styles.footer}>
        <ButtonV3 onClick={() => onSubmit(true)} isLoading={isBackLoading} startIcon={'icon_chevron-left'} color="secondary" borderless={true}>
          {secondaryCtaText}
        </ButtonV3>
        <ButtonV3 onClick={() => onSubmit()} disabled={!isReady} isLoading={isLoading}>
          {primaryCtaText}
        </ButtonV3>
      </Row>
    );
  }
);

MatchingPreferenceFooter.displayName = 'MatchingPreferenceFooter';
