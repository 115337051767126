import { FLOW } from 'hooks/useApp';
import { SidebarOption } from 'hooks/useToolFilters';
import { DegreeResponse } from './degree';
import { SubjectResponse } from './subject';
import { UniversityResponse } from './university';
import { Match } from './matches';
import { CitiesResponse } from './city';
import { UserRole } from './user';
import { TagsResponse, TagType } from './tag';

export interface CandidateUserAttributes {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  authentication_token: string;
  created_at: string;
  userable_type: UserRole.CANDIDATE;
  userable_id: string;
  is_admin: boolean;
  is_confirmed: boolean;
  is_approved: boolean;
  avatar_url: string;
}
export interface CandidateUser {
  id: string;
  type: 'user';
  attributes: CandidateUserAttributes;
}

export interface CandidateUserResponse {
  data: CandidateUser;
}

export interface EducationHistoryAttributes {
  id: string;
  start_date: string;
  end_date: string;
  school_name: string;
  university_id: string;
  degree_id: string;
  subject_id: string;
  subject_name: string;
  description: string[] | string;
  school_type: string;
  location: string;
  current_study_year: number;
  education_type: 'university' | 'school';
  university: UniversityResponse;
  subject: SubjectResponse;
  degree: DegreeResponse;
  degree_name?: string;
  university_name?: string;
  isEndDateFuture?: boolean;
  currently_studying?: boolean;
}

export interface EducationHistoryResponse {
  id: string;
  type: 'education_history';
  attributes: EducationHistoryAttributes;
}

export interface EducationHistoriesResponse {
  data: Array<EducationHistoryResponse>;
}

export interface workRelatedFields {
  id: string;
  start_date: string;
  end_date: string;
  organisation_name: string;
  website: string;
  role: string;
  description: string[] | string;
  generated_description: string[];
  generated_description_disable: boolean;
  location: string;
  _destroy?: boolean;
}

export interface ExtraCurricularAttributes extends workRelatedFields {
  presently_engaged: boolean;
}

export interface ExtraCurricularResponse {
  id: string;
  type: 'extra_curricular';
  attributes: ExtraCurricularAttributes;
}

export interface ExtraCurricularsResponse {
  data: Array<ExtraCurricularResponse>;
}

export interface ExperienceAttributes extends workRelatedFields {
  presently_working: boolean;
}

export interface ExperienceResponse {
  id: string;
  type: 'work_history';
  attributes: ExperienceAttributes;
}

export interface ExperiencesResponse {
  data: Array<ExperienceResponse>;
}

export interface AccomplishmentResponseAttributes {
  id: string;
  title: string;
  description?: string;
  values: Array<string>;
}

export interface AccomplishmentResponseResponse {
  id: string;
  type: 'skills';
  attributes: AccomplishmentResponseAttributes;
}

export interface AccomplishmentsResponseResponse {
  data: Array<AccomplishmentResponseResponse>;
}

export type CandidateResume = {
  byte_size: number;
  checksum: string;
  content_type: string;
  filename: string;
  updated_at: string;
  metadata: { identified: boolean; analyzed: boolean };
};

export interface CandidateAttributes {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  is_visible_to_recruiters: boolean;
  date_of_birth: string;
  exploring_status: boolean;
  resume: CandidateResume;
  resume_url: string;
  avatar_url: string;
  notifications_enabled: boolean;
  score: number;
  study_year: number;
  focus: null;
  matches_count: number;
  unvisited_matches_count: number;
  applications_count: number;
  accepted_applications_count: number;
  linkedin_url: string;
  instagram_url: string;
  bio: string;
  user: CandidateUserResponse;
  notifications_count: number;
  university: UniversityResponse;
  is_privacy_terms_accepted: boolean;
  degree: DegreeResponse;
  subject: SubjectResponse;
  opportunity_types: Array<string>;
  cities: CitiesResponse;
  remote_opportunity: boolean | null;
  reviewed_preferences: boolean;
  touchpointable_kinds: Array<string> | null;
  event_category_ids: Array<string>;
  onboarding_completed: boolean;
  next_job_state_empty: boolean;
  next_internship_state_empty: boolean;
  next_event_state_empty: boolean;
  flow_state: FLOW | null;
  phone_number: string;
  location: string;
  education_histories: EducationHistoriesResponse;
  work_histories: ExperiencesResponse;
  extra_curricular_activities: ExtraCurricularsResponse;
  accomplishments: AccomplishmentsResponseResponse;
  country_ids: Array<string>;
  gender: string;
  physical_disability: string;
  congnitive_disability: string;
  socio_economic_status: string;
  ethnicities: TagsResponse<TagType.Ethnicity>;
  languages: TagsResponse<TagType.Language>;
  country_id: string;
  display_huzzle_verified: boolean;
  viewed_opportunity_count: Record<string, number>;
  huzzle_verified: boolean;
  visa_country_ids: Array<string>;
  language_ids: Array<string>;
  show_add_language_banner: boolean;
  show_cv_builder_banner: boolean;
  show_cv_builder_popup: boolean;
  show_affiliated_societies: boolean;
  show_complete_profile_banner: boolean;
  show_chrome_externsion_banner: boolean;
  profile_complete: boolean;
  extension_installed: boolean;
  external_applied_non_event_count: number;
  external_registrated_event_count: number;
  saved_event_count: number;
  saved_non_event_count: number;
  company_follow_count: number;
  student_society_follow_count: number;
  show_profile_splash_screen: boolean;
  show_bio_in_cv: boolean;
  show_avatar_in_cv: boolean;
}

export interface Candidate {
  id: string;
  type: 'candidate';
  attributes: CandidateAttributes;
}

export interface CandidateResponse {
  data: Candidate;
}
export interface CandidatePaginationResponse {
  data: Array<Candidate>;
  meta: {
    total: number;
    unread: number;
  };
}

export interface CandidateEmailUpdateResponse {
  email: string;
  first_name: string;
  last_name: string;
  current_sign_in_at: string;
}

export interface CandidateNotificationAttributes {
  id: string;
  created_at: string;
  title: string;
  value: string;
  is_read: boolean;
  icon_url: string;
  notifiable: {
    data: Match;
  };
}

export interface CandidateNotification {
  id: string;
  type: 'notification';
  attributes: CandidateNotificationAttributes;
}

export interface CandidateNotificationResponse {
  data: Array<CandidateNotification>;
  meta: {
    total: number;
    unread: number;
  };
}

export enum PROGRAMS {
  UNDERGRADUATE = 'Undergraduate',
  POSTGRADUATE = 'Postgraduate',
}

export enum AI_ONBOARDING_STEPS {
  OPPORTUNITY_TYPE = 'opportunity_type',
  RESPONSIBILITIES = 'responsibilities',
  INDUSTRIES = 'industries',
  LOCATION = 'location',
  REMOTE_PREFERENCE = 'remote_preference',
  MATCHES = 'matches',
}
export const ai_onboarding_step_object = {
  [AI_ONBOARDING_STEPS.OPPORTUNITY_TYPE]: 1,
  [AI_ONBOARDING_STEPS.RESPONSIBILITIES]: 2,
  [AI_ONBOARDING_STEPS.INDUSTRIES]: 3,
  [AI_ONBOARDING_STEPS.LOCATION]: 4,
  [AI_ONBOARDING_STEPS.REMOTE_PREFERENCE]: 5,
  [AI_ONBOARDING_STEPS.MATCHES]: 6,
};

export enum MATCHING_PREFERENCE_STEPS {
  OPPORTUNITY_TYPE = 'opportunity_type',
  RESPONSIBILITIES = 'responsibilities',
  ROLE_LEVEL = 'role_level',
  INTERNSHIP_TYPE = 'internship_type',
  EVENT_TYPE = 'event_type',
  LOCATION = 'location',
  REMOTE_PREFERENCE = 'remote_preference',
  VISA_PREFERENCE = 'visa_preference',
  LANGUAGE = 'language',
  INDUSTRIES = 'industries',
}
export const matching_preference_step_object = {
  [MATCHING_PREFERENCE_STEPS.OPPORTUNITY_TYPE]: 1,
  [MATCHING_PREFERENCE_STEPS.RESPONSIBILITIES]: 2,
  [MATCHING_PREFERENCE_STEPS.ROLE_LEVEL]: 3,
  [MATCHING_PREFERENCE_STEPS.INTERNSHIP_TYPE]: 4,
  [MATCHING_PREFERENCE_STEPS.EVENT_TYPE]: 5,
  [MATCHING_PREFERENCE_STEPS.LOCATION]: 6,
  [MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE]: 7,
  [MATCHING_PREFERENCE_STEPS.VISA_PREFERENCE]: 8,
  [MATCHING_PREFERENCE_STEPS.LANGUAGE]: 9,
  [MATCHING_PREFERENCE_STEPS.INDUSTRIES]: 10,
};

export enum ONBOARDING_STEPS {
  OPPORTUNITY_TYPE = 'opportunity_type',
  UPLOAD_CV = 'cvupload',
  RESPONSIBILITIES = 'responsibilities',
  ROLE_LEVEL = 'role_level',
  INDUSTRIES = 'industries',
  LOCATION = 'location',
  REMOTE_PREFERENCE = 'remote_preference',
  LANGUAGE = 'language',
  BASIC_INFO_STANDARD = 'basic_info_standard',
  BASIC_INFO_SIMPLIFIED = 'basic_info_simplified',
  DEMOGRAPHICS = 'demographics',
}
export const onboarding_step_object = {
  [ONBOARDING_STEPS.OPPORTUNITY_TYPE]: 1,
  [ONBOARDING_STEPS.UPLOAD_CV]: 2,
  [ONBOARDING_STEPS.RESPONSIBILITIES]: 3,
  [ONBOARDING_STEPS.ROLE_LEVEL]: 4,
  [ONBOARDING_STEPS.INDUSTRIES]: 5,
  [ONBOARDING_STEPS.LOCATION]: 6,
  [ONBOARDING_STEPS.REMOTE_PREFERENCE]: 7,
  [ONBOARDING_STEPS.LANGUAGE]: 8,
  [ONBOARDING_STEPS.BASIC_INFO_STANDARD]: 9,
  [ONBOARDING_STEPS.BASIC_INFO_SIMPLIFIED]: 10,
  [ONBOARDING_STEPS.DEMOGRAPHICS]: 11,
};

export enum MANAGER_MATCHING_PREFERENCE_STEPS {
  LOCATION = 1,
  REMOTE_PREFERENCE = 2,
  INDUSTRIES = 3,
}

export interface TCandidateDashboard {
  unread_messages_count: number;
  started_application_count: number;
  applied_touchpoint_count: number;
  registered_touchpoint_count: number;
  match_viewed: boolean;
  resume_present: boolean;
  unread_rooms_count: number;
  notifications_count: number;
  unread_notifications_count: number;
  saved_application_count: number;
  started_or_matches_applications: Array<{
    touchpoint_id: string;
    touchpoint_title: string;
    touchpoint_slug: string;
  }>;
  subscribed_to_societies: boolean;
  registered_events_count: boolean;
  reviewed_preferences: boolean;
  applied_for_job_internship: boolean;
  past_matches_present: boolean;
  registered_for_events: boolean;
  profile_complete: boolean;
  extension_installed: boolean;
}

export enum USER_TYPE {
  NEW = 'NEW',
  OLD = 'OLD',
}
export interface InvitationDataAttributes {
  candidate: CandidateResponse;
}
export interface InvitationData {
  id: string;
  type: 'invitation';
  attributes: InvitationDataAttributes;
}
export interface AcceptInvitationResponse {
  data: InvitationData;
}

export interface InviteCandidate {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  sidebarOptionSelected?: SidebarOption;
  already_in_event?: boolean;
  invited?: boolean;
  subscribed?: boolean;
}

export interface InviteViaEmailCandidateAttributes {
  first_name: string;
  last_name: string;
  candidate: boolean;
  manager: boolean;
  recruiter: boolean;
  subscribed: boolean;
  avatar_url: string;
  name: string;
  member: boolean;
  already_in_event: boolean;
  invited: boolean;
}

export interface InviteSubscriber {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  sidebarOptionSelected?: SidebarOption;
  subscribed?: boolean;
  invited?: boolean;
}

export interface InviteViaEmailSubscriberAttributes {
  first_name: string;
  last_name: string;
  candidate: boolean;
  candidate_id: string;
  subscribed: boolean;
  invited: boolean;
  avatar_url: string;
  name: string;
}
