import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { saveConfirmationModalInstance } from 'components/candidate/Onboarding/SaveConfirmationModal/SaveConfirmationModal.component';
import { mpStepSelectModalInstance } from 'components/candidate/Onboarding/MpStepSelectModal/MpStepSelectModal.component';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useNotification } from './useNotification';
import { useResponsive } from './useResponsive';

export const useCandidateOnboarding = ({
  isReady = true,
}: { isReady?: boolean } = {}) => {
  const router = useRouter();
  const [t] = useTranslation('common');
  const screens = useResponsive();
  const notificationInstance = useNotification();
  const { matching_preference } = router.query;
  const isMatchingPreference = matching_preference === 'true';
  const [isValueEdited, setValueEdited] = useState(false);
  const isMobile = !!screens.sm;

  useEffect(() => {
    if (!isMatchingPreference) return;

    function handleBeforeRouteChange(url: string) {
      if (url.includes(PAGE_ROUTES.CANDIDATE_ONBOARDING) || isReady || isMobile)
        return;

      (async () => {
        await mpStepSelectModalInstance();
      })();

      router.events.emit('routeChangeError');
      throw 'routeChangeError';
    }

    router.events.on('routeChangeStart', handleBeforeRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleBeforeRouteChange);
    };
  }, [router, isReady]);

  const onBackClickMPMobile = async () => {
    const response = await saveConfirmationModalInstance();
    if (response) {
      router.replace(
        {
          query: {
            matching_preference,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };
  const saveNotification = () => {
    if (screens.sm && matching_preference) {
      notificationInstance.acknowledge({
        message: t('saved'),
        showClose: false,
        borderRounded: false,
        variant: 'micro',
      });
    }
  };

  return {
    isValueEdited,
    setValueEdited,
    onBackClickMPMobile,
    saveNotification,
  };
};
