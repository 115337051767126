import React, { memo, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { Icon, IconName } from 'components';
import { Row } from 'components/layout';
import { useResponsive } from 'hooks/useResponsive';
import useScrollOffset from 'hooks/useScrollOffset';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { useAuth } from 'lib/providers/AuthProvider';
import { OPPORTUNITY_TYPE } from 'lib/models/opportunity';
import { MATCHING_PREFERENCE_STEPS } from 'lib/models/candidate';
import { parseArrayResponse } from 'lib/utils/parser';
import { Footer } from '../Footer/Footer.component';
import { mpStepSelectModalInstance } from '../MpStepSelectModal/MpStepSelectModal.component';

import styles from './SidebarMP.module.scss';

export interface SidebarMPProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  onStepSelect: (step: MATCHING_PREFERENCE_STEPS) => void;
  isReady?: boolean;
  isLoading?: boolean;
  onGenerateNewMatches?: () => void;
}

export const SidebarMP: React.FC<SidebarMPProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    // selectedStep = MATCHING_PREFERENCE_STEPS.OPPORTUNITY_TYPE,
    onStepSelect,
    isReady = true,
    onGenerateNewMatches,
    isLoading = false,
  }: SidebarMPProps) => {
    const [t] = useTranslation('candidate-onboarding');
    const [tCommon] = useTranslation('common');
    const screens = useResponsive();
    const bottomRef = useRef<HTMLDivElement>(null);
    const { isWindowScrolled } = useScrollOffset();
    const router = useRouter();
    const { step: stepFromParam = MATCHING_PREFERENCE_STEPS.OPPORTUNITY_TYPE } =
      router.query;

    const [fullyScrolled, setFullyScrolled] = useState(false);

    const contentClassNames = cn(
      {
        [styles.SidebarMP]: true,
      },
      className
    );

    const { candidate } = useAuth();

    const {
      opportunity_types: candidateSelectedOpportunities,
      remote_opportunity,
      cities: citiesResponse,
    } = candidate || {};
    const isJobSelected = candidateSelectedOpportunities?.includes(
      OPPORTUNITY_TYPE.JOB
    );
    const isPartTimeJobSelected = candidateSelectedOpportunities?.includes(
      OPPORTUNITY_TYPE.PART_TIME_JOB
    );
    const isInternshipSelected = candidateSelectedOpportunities?.includes(
      OPPORTUNITY_TYPE.INTERNSHIP
    );
    const isEventSelected = candidateSelectedOpportunities?.includes(
      OPPORTUNITY_TYPE.EVENT
    );

    const isJobOrInternshipOrPartime =
      isJobSelected || isPartTimeJobSelected || isInternshipSelected;
    const isJobOrInternshipOrEvent =
      isJobSelected || isEventSelected || isInternshipSelected;

    const isCitySelected = useMemo(() => {
      const cities = parseArrayResponse(citiesResponse);
      const candidateLocations = cities?.map((city) => city.id);
      return candidateLocations.length;
    }, [citiesResponse]);

    useVisibleOnIntersect({
      elRef: bottomRef,
      threshold: 0,
      onIntersect: () => setFullyScrolled(true),
      onHidden: () => setFullyScrolled(false),
      rootMargin: '0px',
    });

    const sidebarRow = (step: MATCHING_PREFERENCE_STEPS, label: string) => (
      <Row
        align="center"
        justify="space-between"
        columnGap={8}
        onClick={() => onStepSelect(step)}
        className={styles['menu-item-mobile']}
      >
        <p>{label}</p>
        <Icon iconName="chevron-right" size="large" />
      </Row>
    );

    const steps = [
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.OPPORTUNITY_TYPE,
        icon: 'icon_check-list',
        label: t('sidebar.opportunity-types'),
        visible: true,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.RESPONSIBILITIES,
        icon: 'icon_briefcase',
        label: t('sidebar.role-types'),
        visible: isJobOrInternshipOrPartime,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.ROLE_LEVEL,
        label: t('sidebar.experience-level'),
        visible: isJobSelected || isPartTimeJobSelected,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.INTERNSHIP_TYPE,
        label: t('sidebar.internship-types'),
        visible: isInternshipSelected,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.EVENT_TYPE,
        icon: 'icon_party',
        label: t('sidebar.event-categories'),
        visible: isEventSelected,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.LOCATION,
        icon: 'icon_pin',
        label: t('sidebar.locations'),
        visible: true,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE,
        label: t('sidebar.remote-locations'),
        visible:
          remote_opportunity && !isCitySelected && isJobOrInternshipOrPartime,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.VISA_PREFERENCE,
        label: t('sidebar.visa-sponsorship'),
        visible: isJobOrInternshipOrPartime,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.LANGUAGE,
        label: t('sidebar.languages'),
        visible: isJobSelected || isInternshipSelected,
      },
      {
        stepNumber: MATCHING_PREFERENCE_STEPS.INDUSTRIES,
        icon: 'icon_factory',
        label: t('sidebar.industries'),
        visible: isJobOrInternshipOrEvent,
      },
    ];

    const onDesktopOptionSelect = (stepNumber: MATCHING_PREFERENCE_STEPS) => {
      if (isReady) {
        onStepSelect(stepNumber);
      } else {
        mpStepSelectModalInstance();
      }
    };

    return (
      <div className={contentClassNames} style={style} data-testid="SidebarMP">
        {screens.sm ? (
          <>
            <div className={styles['rounded-border']}>
              {sidebarRow(
                MATCHING_PREFERENCE_STEPS.OPPORTUNITY_TYPE,
                t('sidebar.opportunity-types')
              )}
            </div>
            {isJobOrInternshipOrPartime ? (
              <>
                <div className={styles.gap}></div>
                <div className={styles['rounded-border']}>
                  {isJobOrInternshipOrPartime
                    ? sidebarRow(
                        MATCHING_PREFERENCE_STEPS.RESPONSIBILITIES,
                        t('sidebar.role-types')
                      )
                    : null}
                  {isJobSelected || isPartTimeJobSelected
                    ? sidebarRow(
                        MATCHING_PREFERENCE_STEPS.ROLE_LEVEL,
                        t('sidebar.experience-level')
                      )
                    : null}
                  {isInternshipSelected
                    ? sidebarRow(
                        MATCHING_PREFERENCE_STEPS.INTERNSHIP_TYPE,
                        t('sidebar.internship-types')
                      )
                    : null}
                </div>
              </>
            ) : null}

            {isEventSelected ? (
              <>
                <div className={styles.gap}></div>
                <div className={styles['rounded-border']}>
                  {sidebarRow(
                    MATCHING_PREFERENCE_STEPS.EVENT_TYPE,
                    t('sidebar.event-categories')
                  )}
                </div>
              </>
            ) : null}

            <div className={styles.gap}></div>
            <div className={styles['rounded-border']}>
              {sidebarRow(
                MATCHING_PREFERENCE_STEPS.LOCATION,
                t('sidebar.locations')
              )}
              {isJobOrInternshipOrPartime &&
              remote_opportunity &&
              !isCitySelected
                ? sidebarRow(
                    MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE,
                    t('sidebar.remote-locations')
                  )
                : null}
              {isJobOrInternshipOrPartime ? (
                <>
                  {sidebarRow(
                    MATCHING_PREFERENCE_STEPS.VISA_PREFERENCE,
                    t('sidebar.visa-sponsorship')
                  )}
                  {(isJobSelected || isInternshipSelected) &&
                    sidebarRow(
                      MATCHING_PREFERENCE_STEPS.LANGUAGE,
                      t('sidebar.languages')
                    )}
                </>
              ) : null}
            </div>

            <div className={styles.gap}></div>
            {isJobOrInternshipOrEvent && (
              <div className={styles['rounded-border']}>
                {sidebarRow(
                  MATCHING_PREFERENCE_STEPS.INDUSTRIES,
                  t('sidebar.industries')
                )}
              </div>
            )}

            {isReady && (
              <Footer
                isBackLoading={false}
                isFullyScrolled={fullyScrolled}
                isLoading={isLoading}
                isMatchingPreference={true}
                isReady={isReady}
                /** Footer is present on sidebar only on small screen, to generate new matches */
                onSubmit={() => onGenerateNewMatches && onGenerateNewMatches()}
                isWindowScrolled={isWindowScrolled}
                primaryCtaText={tCommon('check-new-matches')}
                className={styles.footer}
              />
            )}
          </>
        ) : (
          steps.map((item) => {
            const { stepNumber, icon, label, visible } = item;
            if (!visible) return;
            return (
              <Row
                key={stepNumber}
                align="center"
                columnGap={10}
                className={cn(styles['menu-item'], {
                  [styles['menu-item-selected']]: stepNumber === stepFromParam,
                })}
                onClick={() => onDesktopOptionSelect(stepNumber)}
              >
                {icon ? (
                  <Icon
                    iconName={icon as IconName}
                    className={cn(styles['icon'], {
                      [styles['icon-selected']]: stepNumber === stepFromParam,
                    })}
                  />
                ) : (
                  <div className={styles['icon-gap']}></div>
                )}
                <p className={styles.label}>{label}</p>
              </Row>
            );
          })
        )}
        <div className={styles['bottom-ref']} ref={bottomRef}></div>
      </div>
    );
  }
);

SidebarMP.displayName = 'SidebarMP';
