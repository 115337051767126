import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { debounce } from 'lodash';

import { Spinner } from 'components';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { Input } from 'components/form';
import { ButtonV3 } from 'components/ComponentV2';
import { MatchingPreferenceFooter } from 'components/InvitePopup/MatchinngPreferenceFooter/MatchingPreferenceFooter';
import { SelectedFilterOptions } from 'hooks/useFilter';
import useScrollOffset from 'hooks/useScrollOffset';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { useResponsive } from 'hooks/useResponsive';
import { useCandidate } from 'hooks/useCandidate';
import { useNotification } from 'hooks/useNotification';
import { useCandidateOnboarding } from 'hooks/useCandidateOnboarding';
import { Option } from 'lib/models/option';
import { MATCHING_PREFERENCE_STEPS } from 'lib/models/candidate';
import { sortedCountriesList } from 'utils/format';
import { Footer } from '../Footer/Footer.component';
import { Cities } from '../Cities/Cities.component';
import { SidebarMP } from '../SidebarMP/SidebarMP.component';
import MobileHeaderMp from '../MobileHeaderMp';

import styles from '../Onboarding.module.scss';
import locationStyles from './Locations.module.scss';

export interface LocationsProps {
  /** Custom class name */
  className?: string;
  candidateLocations: Array<string>;
  remote_opportunity: boolean | null;
  cityCountryList:
    | Array<Option & { country_name: string; country_id: string }>
    | undefined;
  countries: Array<Option> | undefined;
  onNextClick: (
    isRemoteSelected: boolean,
    selectedLocations: Array<string>,
    isBackClicked?: boolean
  ) => void;
  isLoading: boolean;
  isBackLoading: boolean;
  // This prop is for Filter component modal
  openOnModal?: boolean;
  isMatchingPreferences: boolean;
  setSelectedOptions?: Dispatch<SetStateAction<SelectedFilterOptions>>;
  isSocietyMatchingPreference?: boolean;
  onStepSelect?: (step: MATCHING_PREFERENCE_STEPS) => void;
}

export const Locations: React.FC<LocationsProps> = memo(
  ({
    className = '', // custom class name
    candidateLocations,
    remote_opportunity,
    cityCountryList,
    countries,
    onNextClick,
    isLoading,
    isBackLoading,
    openOnModal = false,
    isMatchingPreferences,
    setSelectedOptions,
    isSocietyMatchingPreference,
    onStepSelect,
  }: LocationsProps) => {
    const [t] = useTranslation('candidate-onboarding');
    const [tCommon] = useTranslation('common');
    const bottomRef = useRef<HTMLDivElement>(null);
    const { isWindowScrolled } = useScrollOffset();
    const screens = useResponsive();
    const notificationInstance = useNotification();
    const { updateCandidateInfo } = useCandidate();

    const contentClassNames = cn(styles.container, className);
    const [scrolled, setScrolled] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [fullyScrolled, setFullyScrolled] = useState(false);
    const containerElement = containerRef.current;

    const [selectedLocations, setSelectedLocations] = useState<Array<string>>(
      []
    );
    const [isRemoteSelected, setRemoteSelected] = useState(false);
    const [showRemotePreference, setShowRemotePreference] = useState(false);

    const [search, setSearch] = useState('');

    const onChevronClick = () => {
      if (isValueEdited) {
        onBackClickMPMobile();
      } else {
        onSubmit(false);
      }
    };

    const countriesList = useMemo(() => {
      return sortedCountriesList(countries);
    }, [countries]);

    useVisibleOnIntersect({
      elRef: bottomRef,
      threshold: 0,
      onIntersect: () => setFullyScrolled(true),
      onHidden: () => setFullyScrolled(false),
      rootMargin: '0px',
    });

    useEffect(() => {
      setSelectedLocations(candidateLocations);
    }, [candidateLocations]);

    useEffect(() => {
      if (remote_opportunity) setRemoteSelected(remote_opportunity);
    }, [remote_opportunity]);

    const isReady = !!selectedLocations.length || isRemoteSelected;
    const { setValueEdited, isValueEdited, onBackClickMPMobile } =
      useCandidateOnboarding({ isReady });

    useEffect(() => {
      if (openOnModal && setSelectedOptions) {
        if (selectedLocations?.length > 0) {
          setSelectedOptions((options) => ({
            ...options,
            city_ids: selectedLocations,
          }));
        } else if (
          candidateLocations?.length > 0 &&
          selectedLocations?.length === 0 &&
          isValueEdited
        ) {
          setSelectedOptions((options) => ({
            ...options,
            city_ids: [],
          }));
        }
      }
    }, [selectedLocations, openOnModal, isValueEdited]);
    useEffect(() => {
      if (openOnModal && setSelectedOptions) {
        setSelectedOptions((options) => ({
          ...options,
          is_online_selected: showRemotePreference,
        }));
      }
    }, [openOnModal, showRemotePreference]);

    useEffect(() => {
      if (!openOnModal) return;
      if (
        candidateLocations?.length > 0 &&
        selectedLocations?.length === 0 &&
        !isRemoteSelected
      ) {
        setValueEdited(true);
      } else setValueEdited(false);
    }, [candidateLocations, selectedLocations, isRemoteSelected, openOnModal]);

    const onSubmit = (isBackClicked?: boolean) => {
      setValueEdited(false);
      onNextClick(isRemoteSelected, selectedLocations, isBackClicked);
    };

    useEffect(() => {
      if (!containerElement) return;

      function handler() {
        if (!containerElement) return;
        setScrolled(containerElement.scrollTop > 50);
      }

      containerElement.addEventListener('scroll', handler);

      return () => {
        containerElement.removeEventListener('scroll', handler);
      };
    }, [containerElement]);

    const getLocations = useCallback(
      (countryId: string) => {
        return (
          cityCountryList?.filter(
            (city) => city.hidden === false && city.country_id === countryId
          ) || []
        );
      },
      [cityCountryList]
    );

    const citiesList = useMemo(() => {
      return countriesList?.map((country) => {
        const locations = getLocations(country.id);

        return (
          <Cities
            key={country.id}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            locations={locations}
            search={search}
            openOnModal={openOnModal}
            selectedCountry={country}
            isCheckBoxSquare={true}
          />
        );
      });
    }, [
      selectedLocations,
      search,
      openOnModal,
      setSelectedLocations,
      getLocations,
    ]);

    if (!cityCountryList) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    const showRemoteOption = useMemo(() => {
      if (search) {
        if ('remote'.includes(search.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }, [search]);

    const apiCallToUpdate = async (newSelectionValue: boolean) => {
      try {
        const filteredCountryIds = cityCountryList
          .filter((city) => selectedLocations.includes(city.id))
          .map((city) => city.country_id);
        const uniqueCountryIds = filteredCountryIds.filter(
          (item, index) => filteredCountryIds.indexOf(item) === index
        );

        await updateCandidateInfo({
          partialCandidateAttributes: {
            city_ids: selectedLocations,
            remote_opportunity: newSelectionValue,
            country_ids: newSelectionValue ? uniqueCountryIds : [],
          },
        });
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };
    const debouncedAPICall = React.useRef(
      debounce(async (newSelectionValue) => {
        await apiCallToUpdate(newSelectionValue);
      }, 3000)
    ).current;

    const onRemoteSelection = () => {
      setValueEdited(true);

      const newSelectionValue = !isRemoteSelected;
      setRemoteSelected(newSelectionValue);

      /** For MP
       * Desktop: Autosave on every change
       *          Do not make changes when nothing is selected on mandatory step
       * Mobile: Save on CTA click
       */
      if (
        isMatchingPreferences &&
        !isSocietyMatchingPreference &&
        !screens.sm
      ) {
        debouncedAPICall(newSelectionValue);
      }
    };

    return (
      <div className={styles['content-contianer']}>
        {!isSocietyMatchingPreference && (
          <MobileHeaderMp
            title={t('matching-preferences.locations')}
            onChevronClick={onChevronClick}
          />
        )}
        <div
          className={cn(contentClassNames, {
            [styles['container-society']]: isSocietyMatchingPreference,
          })}
          data-testid="Locations"
          ref={containerRef}
        >
          {!isSocietyMatchingPreference &&
            isMatchingPreferences &&
            onStepSelect && (
              <SidebarMP
                isReady={isReady}
                className={styles.sidebar}
                onStepSelect={onStepSelect}
              />
            )}

          <div
            className={cn({
              [styles.content]: !openOnModal,
              [styles['content-mp']]:
                !openOnModal &&
                isMatchingPreferences &&
                !isSocietyMatchingPreference,
              [styles['content-width-society']]: isSocietyMatchingPreference,
              [styles.fullWidth]: openOnModal,
            })}
          >
            {isSocietyMatchingPreference ? (
              <p className={styles.fractionalStepper}>
                {!isRemoteSelected ? '1/2' : '1/3'}
              </p>
            ) : null}
            {openOnModal ? null : (
              <p className={cn({ [styles.setup]: !openOnModal })}>
                {!isSocietyMatchingPreference
                  ? t('location.title')
                  : tCommon('society-matching-preference.location-title')}
              </p>
            )}

            <Input
              className={cn(styles.search, {
                [styles.sticky]: scrolled && !isSocietyMatchingPreference,
              })}
              placeholder={tCommon('search')}
              startIcon="search"
              startIconSize="small"
              endIconSize="xsmall"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              endIcon={search ? 'cancel' : undefined}
              onEndIconClick={() => setSearch('')}
            />
            {showRemoteOption &&
              (openOnModal ? (
                <ButtonV3
                  variant="text"
                  isFullWidth
                  color="black"
                  endIcon="chevron-right"
                  size="xlarge"
                  onClick={() => setShowRemotePreference(true)}
                  className={locationStyles.remoteButton}
                >
                  {tCommon('remote')}
                </ButtonV3>
              ) : (
                <SelectionTickMark
                  checked={isRemoteSelected}
                  onChange={onRemoteSelection}
                  className={cn(styles.selectionbox, styles.remote)}
                  isTickSquare={true}
                >
                  {tCommon('remote')}
                </SelectionTickMark>
              ))}
            {citiesList}
          </div>
          <div ref={bottomRef}></div>
          {!openOnModal ? (
            !isSocietyMatchingPreference ? (
              <Footer
                isBackLoading={isBackLoading}
                isLoading={isLoading}
                isReady={isReady}
                onSubmit={onSubmit}
                isFullyScrolled={fullyScrolled}
                isWindowScrolled={isWindowScrolled}
                isMatchingPreference={isMatchingPreferences}
                primaryCtaText={tCommon('next')}
              />
            ) : (
              <MatchingPreferenceFooter
                isBackLoading={isBackLoading}
                isReady={isReady}
                onSubmit={onSubmit}
                primaryCtaText={tCommon('next')}
                secondaryCtaText={tCommon('back')}
                isLoading={isLoading}
              />
            )
          ) : null}
        </div>
      </div>
    );
  }
);

Locations.displayName = 'Locations';
