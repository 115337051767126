import React, { memo, useState, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { debounce } from 'lodash';

import { useRouter } from 'next/router';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { Row } from 'components/layout';
import { Button } from 'components/form';
import { useResponsive } from 'hooks/useResponsive';
import { useCandidate } from 'hooks/useCandidate';
import { useNotification } from 'hooks/useNotification';
import { Option } from 'lib/models/option';

import styles from '../Onboarding.module.scss';
import citiesStyles from './Cities.module.scss';

const PER_PAGE_COUNT_1 = 7;
const PER_PAGE_COUNT = 10;

export interface CitiesProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  isCheckBoxSquare?: boolean;
  selectedLocations: Array<string>;
  setSelectedLocations: (locations: Array<string>) => void;
  openOnModal?: boolean;
  search: string;
  selectedCountry: Option;
  locations: Array<Option & { country_name: string; country_id: string }>;
  setValueEdited?: (value: boolean) => void;
}

export const Cities: React.FC<CitiesProps> = memo(
  ({
    selectedLocations,
    setSelectedLocations,
    openOnModal = false,
    isCheckBoxSquare = false,
    search,
    selectedCountry,
    locations,
    setValueEdited,
  }: CitiesProps) => {
    const [t] = useTranslation('common');
    const screens = useResponsive();
    const notificationInstance = useNotification();
    const router = useRouter();
    const { matching_preference: isMatchingPreferences } = router.query;
    const { updateCandidateInfo } = useCandidate();

    const [showCount, setShowCount] = useState(3);
    const [isShowMoreClicked, setShowMoreClicked] = useState(false);

    const trimmedSearch = search.trim();
    const filteredOptions = useMemo(() => {
      return search
        ? locations?.filter(
            (resp) =>
              resp.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) > -1
          )
        : locations;
    }, [trimmedSearch, locations, search]);

    const apiCallToUpdate = async (modifiedSelection: Array<string>) => {
      try {
        await updateCandidateInfo({
          partialCandidateAttributes: {
            city_ids: modifiedSelection,
          },
        });
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };

    const debouncedAPICall = React.useRef(
      debounce(async (modifiedSelection) => {
        await apiCallToUpdate(modifiedSelection);
      }, 3000)
    ).current;

    const onLocationSelect = useCallback(
      (locationId: string) => {
        setValueEdited && setValueEdited(true);

        const index = selectedLocations?.findIndex((id) => id === locationId);

        let modifiedSelection = [];
        if (index === -1)
          modifiedSelection = [...selectedLocations, locationId];
        else
          modifiedSelection =
            selectedLocations &&
            selectedLocations.filter((item) => item !== locationId);

        /** For MP
         * Desktop: Autosave on every change
         *          Do not make changes when nothing is selected on mandatory step
         * Mobile: Save on CTA click
         */
        setSelectedLocations(modifiedSelection);

        if (isMatchingPreferences && !screens.sm) {
          debouncedAPICall(modifiedSelection);
        }
      },
      [selectedLocations, setSelectedLocations, screens.sm]
    );

    const hasMoreRecords = useMemo(() => {
      return showCount < filteredOptions?.length;
    }, [showCount, filteredOptions]);

    return (
      <>
        {filteredOptions?.length ? (
          <>
            <p className={citiesStyles.countryName}>{selectedCountry.label}</p>
            <div
              className={cn(styles.checkboxesLocation, {
                [citiesStyles.modalCheckboxes]: openOnModal,
              })}
            >
              {filteredOptions
                .slice(0, isShowMoreClicked ? showCount : 3)
                .map((city) => (
                  <Row
                    key={city.id}
                    align="center"
                    justify="space-between"
                    wrap={false}
                    className={cn(styles.checkboxesLocationRow, {
                      [citiesStyles.modalCheckboxesRow]: openOnModal,
                    })}
                  >
                    <SelectionTickMark
                      checked={selectedLocations?.some((id) => city.id === id)}
                      onChange={() => onLocationSelect(city.id)}
                      className={cn(
                        { [styles.selectionbox]: !openOnModal },
                        { [citiesStyles.modalSelectionbox]: openOnModal }
                      )}
                      isTickSquare={isCheckBoxSquare}
                    >
                      {city.label}
                    </SelectionTickMark>
                    {openOnModal ? (
                      <hr className={citiesStyles.separator}></hr>
                    ) : null}
                  </Row>
                ))}
            </div>

            <Row
              align="center"
              columnGap={8}
              className={citiesStyles.countsRow}
            >
              {filteredOptions?.length ? (
                <p>
                  {t('showing-count', {
                    count: Math.min(showCount, filteredOptions.length),
                    totalCount: filteredOptions.length,
                  })}
                </p>
              ) : null}
              {hasMoreRecords ? (
                <Button
                  variant="text"
                  color="blue"
                  onClick={() => {
                    const countToAdd = isShowMoreClicked
                      ? PER_PAGE_COUNT
                      : PER_PAGE_COUNT_1;
                    setShowCount((count) =>
                      Math.min(count + countToAdd, filteredOptions?.length)
                    );
                    setShowMoreClicked(true);
                  }}
                  className={citiesStyles.countsButton}
                >
                  {t('show-more')}
                </Button>
              ) : null}
            </Row>
          </>
        ) : null}
      </>
    );
  }
);

Cities.displayName = 'Cities';
